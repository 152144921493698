import { Anchor, Flex, Text } from "sparta";
import { Link } from "@remix-run/react";
import type { AdvertisementData } from "~/sdk/qs1/Advertisement";
import { QuestionMarkCircledIcon } from "@radix-ui/react-icons";

interface IAdvertisementComponent {
  advertisement: AdvertisementData;
}

export default function AdvertisementComponent({
  advertisement,
}: IAdvertisementComponent) {
  return (
    <Flex direction="column" align="center" mt="2">
      <Link to={advertisement.adLink} target="_blank">
        <Flex align="center" justify="center">
          <img
            src={advertisement.thumbnail.url}
            style={{ width: "100%", maxWidth: 970 }}
            alt={advertisement.title}
          />
        </Flex>
      </Link>
      <Flex width="100%" maxWidth="970px" justify="end">
        <Text align="right" size="1" asChild>
          <Anchor
            href={"https://share.hsforms.com/1VZawn-dbREiZVTndvvMZyge2kzr"}
            target="_blank"
          >
            Partner Content <QuestionMarkCircledIcon />
          </Anchor>
        </Text>
      </Flex>
    </Flex>
  );
}
